@charset "UTF-8";

// IMPPRT INIT

@import "lib/_sanitize";    // Reset CSS
@import "_setting";
$type: "";
@import "_common";


// FOR HEADER DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (max-width:$breakpoint_tb) {
 $type: header_sp;
 @import "_common";
}

// FOR HEADER DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_tb + 1) {
 $type: header_pc;
 @import "_common";
}

// FOR SMARTPHONE DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (max-width:$breakpoint_sp) {
	$type: sp;
	@import "_common";
}

// FOR LARGE DISPLAY SIZE
//-----------------------------------------------------------------
@media print, screen and (min-width:$breakpoint_pc) {
	$type: pc;
	@import "_common";
}

// FOR TABLET DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_pc) and (max-width:$breakpoint_tb) {
  $type: tb;
  @import "_common";
}

// FOR PC DISPLAY SIZE S
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_pc) and (max-width:1160px) {
  #globalHeader .ghInner .ghSubNav .ghContact{
    width: 300px;
  }
  #globalHeader .ghInner .ghSubNav .ghContact a:nth-child(1){
    flex-basis: 40%;
    font-size: 14px;
  }
  #globalHeader .ghInner .ghSubNav .ghContact a:nth-child(2){
    flex-basis: 60%;
    font-size: 14px;
  }
  #globalHeader .ghInner .ghSubNav{
    margin-right: 200px;
    font-size: 15px;
  }
}


// STYLE FOR PRINT
//-----------------------------------------------------------------
@media print {
	$type: print;
}
