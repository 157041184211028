//アクセシビリティ
a,
button,
input,
select,
textarea,
summary {
	outline: none;
}

a:focus-visible,
button:focus-visible,
input:focus-visible,
select:focus-visible,
textarea:focus-visible,
summary:focus-visible {
	outline: 2px solid $c_base;
}


body {
	@if $type =="" {
		font-weight: 500;
		color: $c_text;
		@include fontUsu;
		overflow-x: hidden;
		position: relative;
		letter-spacing: 0.06em;
	}

	@if $type ==sp {
		// font-size: 13px;
		font-size: 14px;
		line-height: 2;
		// padding-top: 97px;
	}

	@if $type ==pc {
		font-size: 16px;
		line-height: 1.75;
		// padding-top: 122px;
	}
}

a {
	@if $type =="" {
		// color: #000000;
		// text-decoration: none;
	}
}

ul,
ol {
	@if $type =="" {
		list-style-type: none;
	}

	@if $type ==sp {
		font-size: 14px;
		// line-height: 1.75;
	}
}

img {
	@if $type =="" {
		max-width: 100%;
		height: auto;
		image-rendering: -webkit-optimize-contrast;
	}
}

* {
	@if $type =="" {
		box-sizing: border-box;
	}
}

.tel {
	@if $type ==sp {
		color: $c_base;
		text-decoration: underline;
	}

	@if $type ==pc {
		color: inherit !important;
		text-decoration: none !important;
		pointer-events: none;
	}
}

sup,
sub {
	@if $type =="" {
		font-size: .6em;
	}
}

.dInlineBlock {
	@if $type =="" {
		display: inline-block !important;
	}
}

.fontEn {
	@if $type =="" {
		@include fontEn;
	}
}

.btn {
	display: inline-block;
	font-weight: 600;
	color: #fff;
	padding: 14px 70px 14px 48px;
	font-size: 24px;
	position: relative;
	transition: 0.3s;
	text-decoration: none;
	background: #2e2e2e;
	text-align: left;
	line-height: 1.6 !important;
	border-radius: 8px;
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);

	@if $type ==sp {
		width: 100%;
		font-size: 20px;
	}

	@if $type ==pc {
		font-size: 24px;
	}

	&:hover {
		opacity: .7;
	}

	&.mid {
		display: inline-block;
		font-size: 18px;


		@if $type ==sp {
			font-size: 15px;
			padding: 10px 13px;

		}

		@if $type ==pc {
			padding-left: 34px;
			padding-block: 11px;
			padding-right: 70px;

		}

		>.c-arrow {
			@if $type ==pc {
				width: 27px;
				height: 27px;

				&::before {
					width: 27px;
					height: 27px;
				}

				&::after {
					width: 6px;
					height: auto;
					left: calc(50% + 0px);
				}
			}
		}
	}

	&.semi {
		font-size: 16px;
		padding-left: 18px;
		padding-block: 11px;
		padding-right: 45px;

		@if $type ==sp {
			font-size: 14px;
		}

		@if $type ==pc {
			width: 240px;
		}

		.c-arrow {
			@if $type =="" {
				position: absolute;
			}

			@if $type ==sp {
				display: block;
				width: 20px;
				height: 20px;
			}

			@if $type ==pc {
				width: 21px;
				height: 21px;
				right: 16px;
			}

			&::before {
				@if $type ==sp {
					width: 18px;
					height: 18px;

				}

				@if $type ==pc {
					width: 21px;
					height: 21px;
				}
			}

			&::after {
				@if $type =="" {}

				@if $type ==sp {
					width: 4px;
					height: auto;
				}

				@if $type ==pc {
					width: 6px;
					height: auto;
					left: calc(50% - 0px);
				}
			}
		}
	}

	&.small {

		font-size: 16px;
		padding: 8px 55px 8px 16px;

		@if $type ==pc {
			width: 160px;
		}

		>.c-arrow {
			@if $type ==pc {
				width: 21px;
				height: 21px;

				&::before {
					width: 21px;
					height: 21px;
				}

				&::after {
					width: 6px;
					height: auto;
					left: calc(50% + 1px);

				}
			}
		}
	}

	&.orange {
		background: #f3961e;

	}

	&.lightgray {
		background: #4c4c4c;

		>span {
			color: #2e2e2e;
		}
	}

	&.blue {
		background: #3363ab;

		&::after {
			aspect-ratio: 83/150;
		}

		>span {
			color: #2e2e2e;
		}
	}

	&.skyblue {
		background: #2b91c1;

		&::after {
			aspect-ratio: 83/150;
		}

		>span {
			color: #2e2e2e;
		}
	}

	&.green {
		background: $c_base;

		&::after {
			aspect-ratio: 11/15;
		}

		>span {
			color: #2e2e2e;
		}
	}

	.c-arrow {
		@if $type =="" {
			position: absolute;
		}

		@if $type ==sp {
			display: block;
			width: 20px;
			height: 20px;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
		}

		@if $type ==pc {
			width: 36px;
			height: 36px;
			right: 24px;
			top: 50%;
			transform: translateY(-50%);
		}

		&::before {
			@if $type ==sp {
				content: '';
				width: 18px;
				height: 18px;
				background: $c_base;
				border-radius: 50%;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				margin: auto;
			}

			@if $type ==pc {
				content: '';
				width: 36px;
				height: 36px;
				background: $c_base;
				border-radius: 50%;
				position: absolute;
				top: 0;
				left: 0px;
				bottom: 0;
				margin: auto;
			}
		}

		&::after {
			@if $type ==sp {
				content: '';
				width: 4px;
				height: auto;
				position: absolute;
				background: url(../images/common/arrow-white.svg);
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				aspect-ratio: 83/150;
				background-size: contain;

			}

			@if $type ==pc {
				content: '';
				position: absolute;
				width: 9px;
				height: auto;
				background: url(../images/common/arrow-white.svg);
				top: 50%;
				left: calc(50% + 0px);
				transform: translate(-50%, -50%);
				aspect-ratio: 83/150;
				background-size: contain;
				background-repeat: no-repeat;

			}
		}

		&.black {
			&::before {
				background: #fff;
			}

			&::after {
				background: url(../images/common/arrow.svg);
				background-size: contain;
				background-repeat: no-repeat;
			}
		}

		&.orange {
			&::before {
				background: #fff;
			}

			&::after {
				background: url(../images/common/arrow-orange.svg);
				background-size: contain;
				background-repeat: no-repeat;
				aspect-ratio: 83/150;
			}
		}

		&.lightgray {
			&::before {
				background: #fff;
			}

			&::after {
				background: url(../images/common/arrow-lightgray.svg);
				aspect-ratio: 83/150;
				background-size: contain;
				background-repeat: no-repeat;

			}
		}

		&.blue {
			&::before {
				background: #fff;
			}

			&::after {
				background: url(../images/common/arrow-blue.svg);
				aspect-ratio: 83/150;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}

		&.skyblue {
			&::before {
				background: #fff;
			}

			&::after {
				background: url(../images/common/arrow-lightblue.svg);
				background-size: contain;
				background-repeat: no-repeat;
				aspect-ratio: 83/150;

			}
		}

		&.green {
			&::before {
				background: #fff;
			}

			&::after {
				background: url(../images/common/arrow-green.svg);
				background-size: contain;
				background-repeat: no-repeat;
				aspect-ratio: 83/150;
			}
		}


	}
}


// @keyframes isUsual {
// 	0% {
// 		position: fixed;
// 		top: 0;
// 	}

// 	100% {
// 		position: absolute;
// 		top: 0;
// 	}
// }

// @keyframes isFixed {
// 	0% {
// 		top: -120px;
// 	}

// 	100% {
// 		top: 0;
// 	}
// }

#globalHeader {
	@if $type =="" {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 20;

		&.isUsual {
			// animation: isUsual .2s both;
		}

		&.isFixed {
			position: fixed;
			// animation: isFixed .2s both;
			background-color: #fff;
		}
	}

	@if $type ==header_sp {}

	@if $type ==header_pc {
		&.isFixed {
			.ghInner .ghSubNav>.ghSubNavList {}

			.ghInner .ghLogo {}
		}

	}

	.lineLabel {
		@if $type =="" {
			background: $c_base;
		}

		.labelInner {
			@if $type =="" {
				display: flex;
				justify-content: space-between;
				margin-inline: auto;
			}

			@if $type ==sp {
				padding-block: 5px;
				width: 95%;
			}

			@if $type ==pc {
				max-width: 1570px;
				padding-block: 5px;
				width: 90%;
			}


			p {
				@if $type =="" {

					color: #fff;
					letter-spacing: 0.05em;
				}

				@if $type ==sp {
					font-size: 9px;
					line-height: 1.4;
				}

				@if $type ==pc {
					font-size: 13px;
				}
			}

			.labelLink {
				@if $type =="" {
					font-size: 14px;
					color: #fff;
					font-weight: 500;
					letter-spacing: 0.05em;
					position: relative;
				}

				&::before {
					@if $type =="" {
						position: absolute;
						content: "";
						background: url(../images/common/ytube-icon.png);
						background-repeat: no-repeat;
					}

					@if $type ==pc {
						width: 16px;
						height: 16px;
						background-size: contain;
						left: -28px;
						top: 5px;
					}
				}
			}


		}
	}

	.ghInner {
		@if $type =="" {}

		@if $type ==header_sp {
			padding: 30px 20px 66px;
			height: 62px;
		}

		@if $type ==header_pc {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			align-items: center;
			position: relative;
			margin-inline: auto;
			padding-block: 14px;
			width: 90%;
		}

		@if $type ==tb {}

		.ghLogo {
			@if $type =="" {}

			@if $type ==header_sp {
				max-width: 162px;
			}

			@if $type ==header_pc {
				max-width: 216px;
				transition: 0.25s;
				position: relative;
				top: 0;
			}

			a {
				@if $type =="" {
					display: block;
				}

				img {
					@if $type ==header_sp {
						width: 160px;
					}

					@if $type ==header_pc {
						width: 246px;
					}
				}

				span {
					@include fontOs;
					color: $c_base;
					display: inline-block;

					@if $type ==header_sp {
						font-size: 13px;
						margin-top: 12px;
						padding-left: 5px;
					}

					@if $type ==header_pc {
						font-size: 18px;
						padding-left: 10px;
						margin-top: 25px;
					}
				}
			}
		}

		.ghSubNav {
			@if $type =="" {}

			@if $type ==header_sp {
				display: none;
			}

			@if $type ==header_pc {
				font-size: 16px;
				font-weight: bold;
				margin-right: 0 !important;
			}

			&>.ghSubNavTop {
				@if $type =="" {
					display: flex;
					justify-content: flex-end;
				}

				@if $type ==pc {
					gap: 18px;
					align-items: center;
				}


				li {
					&:not(:last-child) {
						a {
							@if $type =="" {
								color: $c_base;
								width: 100%;
								position: relative;
								display: flex;
								text-decoration: none;
							}

							@if $type ==pc {
								font-size: 14px;
								text-underline-offset: 5px;
								gap: 8px;
								align-items: center;
								transition: all 0.3s;
							}

							&::before {
								content: "";
								position: absolute;
								background: $c_base;
								height: 1px;
								width: 100%;
								bottom: -2px;
								left: 0;

							}

							.icon {
								position: relative;
								display: block;
								width: 16px;
								height: 16px;
								border-radius: 3px;
								border: solid 1px $c_base;

								&::before {
									content: "";
									position: absolute;
									top: 50%;
									left: 5px;
									transform: translateY(-50%);
									border: 3px solid transparent;
									border-left: 5px solid $c_base;

								}
							}

							&:hover,
							&:focus {
								&::before {
									visibility: hidden;
								}
							}
						}
					}


					&.searchBox {
						border-radius: 4px;

						@if $type ==pc {
							width: 224px;
						}

						a {
							@if $type =="" {
								position: relative;
								padding-block: 7px;
								display: block;
								background: #e1e1e1;
								text-decoration: none;
								border-radius: 4px;
							}

							@if $type ==pc {
								min-height: 40px;
								width: 100%;
							}

							&::before {
								position: absolute;
								content: "";
								background: url(../images/common/mushi.png);
								background-repeat: no-repeat;
								background-size: contain;
								right: 12px;
								top: 50%;
								transform: translateY(-50%);
								width: 20px;
								height: 20px;
								left: unset;
							}
						}
					}
				}
			}



			&>.ghSubNavList {
				@if $type =="" {}

				@if $type ==header_sp {
					display: none !important;
				}

				@if $type ==header_pc {
					display: flex;
					position: relative;
					transition: top 0.25s;
					top: 0;
					margin-top: 14px;
				}

				>li {
					+li {
						@if $type ==header_sp {
							margin-left: 16px;
						}

						@if $type ==header_pc {
							margin-left: 31px;
						}

						@if $type ==pc {
							margin-left: 31px;
						}
					}

					a {
						@if $type =="" {
							display: block;
							text-decoration: none;
							color: $c_base;
						}

						@if $type ==header_sp {}

						@if $type ==header_pc {
							transition: .2s opacity;
							font-size: 16px;

							&:hover {
								text-decoration: underline;
								text-decoration-color: $c_base;
								text-decoration-thickness: 2px;
								text-underline-offset: 3px;

								.on {
									color: $c_base;
									opacity: 1;
								}
							}

						}

					}
				}
			}

		}
	}

	#ghMenuBtn {
		@if $type =="" {
			background-color: transparent;
			border: none;
			cursor: pointer;
			outline: none;
			padding: 0;
			appearance: none;
		}

		@if $type ==header_sp {
			width: 45px;
			height: 45px;
			background: $c_base;
			position: absolute;
			top: 42px;
			right: 10px;
			cursor: pointer;
			border-radius: 50%;
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
			z-index: 40;

			>span {
				background: #fff;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				margin: auto;
				transition: .2s transform, .2s opacity;
			}

			>span {
				width: 17px;
				height: 2px;

				&:nth-child(1) {
					transform: translateY(-5px);
				}

				&:nth-child(3) {
					transform: translateY(5px);
				}
			}

			&.isOpen {
				z-index: 20;
				background: #fff;

				>span {
					background: $c_base;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						opacity: 0;
					}

					&:nth-child(3) {
						transform: rotate(-45deg);
					}
				}
			}
		}

		@if $type ==header_pc {
			display: none !important;
		}

		@if $type ==tb {
			width: 60px;
			height: 60px;
		}

	}

	.ghMenu {
		@if $type =="" {
			background: #edf9e5;
		}

		@if $type ==header_sp {
			display: none;
			width: 100%;
			height: calc(100% - 60px);
			position: fixed;
			top: 10px;
			left: 0;
			overflow: hidden;
		}

		@if $type ==header_pc {
			display: block !important;
		}

		@if $type ==tb {}

		.ghNav {
			@if $type =="" {}

			@if $type ==header_sp {
				padding: 30px 20px 40px;
				height: 100%;
				overflow-y: auto;





				.hamLogo {
					max-width: 160px;
					width: 100%;
				}

			}

			@if $type ==header_pc {
				display: none;

				.ghSubNavList {
					display: none !important;
				}
			}



			>ul:not([class]) {
				@if $type =="" {
					margin: 0 auto;
					font-weight: bold;
				}


				@if $type ==header_pc {
					display: flex;
				}

				>li {
					@if $type =="" {}

					@if $type ==header_sp {}

					@if $type ==header_pc {
						flex: 1;
						position: relative;

						+li:before,
						&:nth-child(1):before,
						&:last-child:after {
							content: "";
							display: block;
							width: 2px;
							height: calc(100% - 20px);
							background: #fff;
							position: absolute;
							top: 10px;
						}

						+li,
						&:nth-child(1) {
							&:before {
								left: 0;
							}
						}

						&:last-child {
							&:after {
								right: 0;
							}
						}
					}

					>a {
						@if $type =="" {}

						@if $type ==header_sp {
							display: block;
							padding: 15px 0px;
							color: #222;
							position: relative;
							font-size: 15px;
							letter-spacing: 0.025em;
							background: #fff;

						}

						@if $type ==header_pc {
							display: block;
							padding: 18px 10px;
							text-align: center;
							transition: .2s background;

							&:hover {
								background: $c_baseD;
							}
						}
					}
				}

			}



			.btnNav_large {
				@if $type ==header_sp {
					margin-top: 20px;



					li {
						width: 100%;
						background: #fff;
						border-radius: 8px;
						box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

						a {
							padding-block: 18px;
							padding-inline: 22px;
							color: #222;
							display: block;
							position: relative;
							text-decoration: none;
							font-size: 18px;
							display: flex;
							gap: 20px;
							align-items: center;

							.img {
								width: 40px;

								&.lawyer {
									img {
										width: 36px;
									}

								}

								&.doctor {
									img {
										width: 38px;
									}

								}

								&.clinic {
									img {
										width: 30px;
									}

								}

								&.professionals {
									img {
										width: 30px;
									}
								}

								&.hojin {
									img {
										width: 30px;
									}
								}
							}

							.c-arrow {
								position: absolute;
								right: 20px;
								top: 50%;
								border-radius: 50px;
								transform: translateY(-50%);

								&::before {
									content: "";
									position: absolute;
									background: $c_base;
									width: calc(36px/2);
									height: calc(36px/2);
									top: 50%;
									left: 50%;
									border-radius: 50px;
									transform: translate(-50%, -50%);
								}

								&::after {
									content: "";
									background: url(../images/common/arrow-white.svg);
									position: absolute;
									background-size: contain;
									background-repeat: no-repeat;
									top: 50%;
									left: calc(50% + 1px);
									transform: translate(-50%, -50%);
									width: 4px;
									height: auto;
									aspect-ratio: 83/150;
								}
							}
						}

						&+li {
							margin-top: 14px;
						}
					}
				}


			}

			.sp_navContactBtn {
				@if $type ==header_sp {
					margin-top: 30px;

					a {
						display: flex;
						padding: 18px 20px;
						background: #f3961e;
						border-radius: 6px;
						gap: 20px;
						align-items: center;
						font-size: 18px;
						box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
						text-decoration: none;

						.mailIcon {
							width: 40px;
							display: flex;
							justify-content: center;
							align-items: center;

							img {
								width: 30px;
								aspect-ratio: 44/32;
							}

						}

						.text {
							color: #fff;
							font-weight: 600;
						}
					}
				}

			}
		}
	}
}


main {
	@if $type =="" {
		display: block;
	}

	@if $type ==header_sp {}

	@if $type ==header_pc {}

	@if $type ==tb {}

}

.section {
	@if $type =="" {}

	@if $type ==sp {}

	@if $type ==pc {}

	.inner {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {
			@include maxPC;
		}

	}
}

.second {
	@if $type ==pc {
		padding-top: 120px;
	}

	#globalHeader {
		@if $type =="" {
			background: #fff;
		}
	}
}

//#globalHeader

#globalFooter {
	@if $type =="" {
		color: #fff;

	}

	@if $type ==sp {
		background-image: url(../images/common/footer_bg_sp.png);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		padding-top: 35px;
		padding-bottom: 40px;
	}

	@if $type ==pc {
		background-image: url(../images/common/footer_bg.png);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.inner {
		@if $type ==sp {
			padding-inline: 20px
		}

		@if $type ==pc {
			@include maxPC;
		}

		.gfLogo {
			a {
				@if $type =="" {
					display: block;
					text-decoration: none;
				}

				@if $type ==sp {
					max-width: 160px;
				}

				@if $type ==pc {
					max-width: 216px;
				}
			}
		}

		.gfInner {
			@if $type ==pc {
				display: flex;
				justify-content: space-between;
				margin-top: 40px;
			}


			.gf1 {
				@if $type ==sp {
					margin-top: 20px;
				}

				.gfLogo+p {
					@if $type ==sp {
						margin-top: 30px;
						font-size: 13px;
						line-height: 1.53;
						letter-spacing: 0.12em;
					}

					@if $type ==pc {
						margin-top: 36px;
					}

				}



				p {
					.telfax {
						@if $type ==sp {
							font-size: 15px;
							font-weight: 600;
							line-height: 1.7;
							margin-top: 16px;
							display: block;
						}
					}
				}
			}


			.gf2 {
				@if $type ==sp {
					margin-top: 34px
				}

				ul {
					@if $type ==pc {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						gap: 50px;
						row-gap: 16px;
						max-width: 470px;
					}

					li {

						@if $type ==pc {
							width: calc((100% - 50px)/2);
						}

						a {
							@if $type =="" {
								font-weight: 500;
								color: #fff;
								text-decoration: none;
								letter-spacing: 0.06em;
							}

							@if $type ==sp {
								font-size: 12px;

							}

							@if $type ==pc {
								font-size: 14px;

							}
						}
					}
				}
			}
		}

		.gfCopyright {
			@if $type ==sp {
				font-size: 9px;
				margin-top: 40px;

			}

			@if $type ==pc {
				font-size: 13px;
				margin-top: 130px;
			}
		}
	}


	#pageTop {
		@if $type =="" {
			display: block;
			border-radius: 50%;
			text-align: center;
			position: absolute;
			cursor: pointer;
			z-index: 10;
		}

		@if $type ==sp {
			right: 16px;
			bottom: 32px;
			width: 32px;
			height: 32px;
		}

		@if $type ==pc {
			width: 53px;
			height: 53px;
			right: 38px;
			bottom: 53px;
			transition: .2s transform;

			&:hover {
				transform: translateY(-10px);
				background-color: rgba(255, 255, 255, 0.7);
			}
		}

		&:before {
			content: '';
			width: 53px;
			height: 53px;
			background: #fff;
			border-radius: 50%;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			margin: auto;

			@if $type ==sp {
				width: 32px;
				height: 32px;
				top: 0;
				left: 0;
				bottom: 0;
				margin: auto;
			}
		}

		&:after {
			@if $type =="" {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			@if $type ==sp {
				background: url(../images/common/pagetop-arrow_sp.png);
				width: calc(20px/2);
				height: auto;
				background-size: contain;
				background-repeat: no-repeat;
				aspect-ratio: 20/12;
			}

			@if $type ==pc {
				background: url(../images/common/pagetop-arrow.png);
				width: 16px;
				height: auto;
				background-size: contain;
				background-repeat: no-repeat;
				aspect-ratio: 32/21;
			}
		}
	}
}

//#globalFooter


//common color
@if $type=="" {
	.bgGreen {
		background: $c_base !important;
	}

	.bgGray {
		background: #f3f3f3 !important;
	}

	.bgBlack {
		background: #2e2e2e !important;
	}

	.bgSkyblue {
		background: #449ab6 !important;
	}

	.greenText {
		color: $c_base !important;
	}

	.redText {
		color: #d41328 !important;
	}

	.c-chiken_bg {
		background: #3363ab !important;
	}

	.c-bengo_bg {
		background: $c_base !important;
	}

	.c-pro_bg {
		background: #4c4c4c !important;
	}

	.c-hojin_bg {
		background: #6d9d2d !important;
	}
}




/* ココから下には記入禁止 */
.forPC {
	@if $type ==sp {
		display: none !important;
	}
}

.forSP {
	@if $type ==pc {
		display: none !important;
	}
}