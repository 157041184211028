@charset "UTF-8";
/*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  vertical-align: top;
}

table {
  border-collapse: collapse;
}

button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, html [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*サイトカラー暗め*/
/*テキストに使うフォントカラー*/
/*未設定*/
/*
.f_em{ @include fontUsu; }
.f_os{ @include fontOs; }
.f_min{ @include fontMin; }
.f_lc{ @include fontLc; }
*/
a,
button,
input,
select,
textarea,
summary {
  outline: none;
}

a:focus-visible,
button:focus-visible,
input:focus-visible,
select:focus-visible,
textarea:focus-visible,
summary:focus-visible {
  outline: 2px solid #005b2a;
}

body {
  font-weight: 500;
  color: #333;
  font-family: "Noto Sans JP", sans-serif;
  overflow-x: hidden;
  position: relative;
  letter-spacing: 0.06em;
}

ul,
ol {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

* {
  box-sizing: border-box;
}

sup,
sub {
  font-size: 0.6em;
}

.dInlineBlock {
  display: inline-block !important;
}

.fontEn {
  font-family: "Lato", sans-serif;
}

.btn {
  display: inline-block;
  font-weight: 600;
  color: #fff;
  padding: 14px 70px 14px 48px;
  font-size: 24px;
  position: relative;
  transition: 0.3s;
  text-decoration: none;
  background: #2e2e2e;
  text-align: left;
  line-height: 1.6 !important;
  border-radius: 8px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
}
.btn:hover {
  opacity: 0.7;
}
.btn.mid {
  display: inline-block;
  font-size: 18px;
}
.btn.semi {
  font-size: 16px;
  padding-left: 18px;
  padding-block: 11px;
  padding-right: 45px;
}
.btn.semi .c-arrow {
  position: absolute;
}
.btn.small {
  font-size: 16px;
  padding: 8px 55px 8px 16px;
}
.btn.orange {
  background: #f3961e;
}
.btn.lightgray {
  background: #4c4c4c;
}
.btn.lightgray > span {
  color: #2e2e2e;
}
.btn.blue {
  background: #3363ab;
}
.btn.blue::after {
  aspect-ratio: 83/150;
}
.btn.blue > span {
  color: #2e2e2e;
}
.btn.skyblue {
  background: #2b91c1;
}
.btn.skyblue::after {
  aspect-ratio: 83/150;
}
.btn.skyblue > span {
  color: #2e2e2e;
}
.btn.green {
  background: #005b2a;
}
.btn.green::after {
  aspect-ratio: 11/15;
}
.btn.green > span {
  color: #2e2e2e;
}
.btn .c-arrow {
  position: absolute;
}
.btn .c-arrow.black::before {
  background: #fff;
}
.btn .c-arrow.black::after {
  background: url(../images/common/arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.btn .c-arrow.orange::before {
  background: #fff;
}
.btn .c-arrow.orange::after {
  background: url(../images/common/arrow-orange.svg);
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 83/150;
}
.btn .c-arrow.lightgray::before {
  background: #fff;
}
.btn .c-arrow.lightgray::after {
  background: url(../images/common/arrow-lightgray.svg);
  aspect-ratio: 83/150;
  background-size: contain;
  background-repeat: no-repeat;
}
.btn .c-arrow.blue::before {
  background: #fff;
}
.btn .c-arrow.blue::after {
  background: url(../images/common/arrow-blue.svg);
  aspect-ratio: 83/150;
  background-size: contain;
  background-repeat: no-repeat;
}
.btn .c-arrow.skyblue::before {
  background: #fff;
}
.btn .c-arrow.skyblue::after {
  background: url(../images/common/arrow-lightblue.svg);
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 83/150;
}
.btn .c-arrow.green::before {
  background: #fff;
}
.btn .c-arrow.green::after {
  background: url(../images/common/arrow-green.svg);
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 83/150;
}

#globalHeader {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}
#globalHeader.isFixed {
  position: fixed;
  background-color: #fff;
}
#globalHeader .lineLabel {
  background: #005b2a;
}
#globalHeader .lineLabel .labelInner {
  display: flex;
  justify-content: space-between;
  margin-inline: auto;
}
#globalHeader .lineLabel .labelInner p {
  color: #fff;
  letter-spacing: 0.05em;
}
#globalHeader .lineLabel .labelInner .labelLink {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.05em;
  position: relative;
}
#globalHeader .lineLabel .labelInner .labelLink::before {
  position: absolute;
  content: "";
  background: url(../images/common/ytube-icon.png);
  background-repeat: no-repeat;
}
#globalHeader .ghInner .ghLogo a {
  display: block;
}
#globalHeader .ghInner .ghLogo a span {
  font-family: "Oswald", sans-serif;
  color: #005b2a;
  display: inline-block;
}
#globalHeader .ghInner .ghSubNav > .ghSubNavTop {
  display: flex;
  justify-content: flex-end;
}
#globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a {
  color: #005b2a;
  width: 100%;
  position: relative;
  display: flex;
  text-decoration: none;
}
#globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a::before {
  content: "";
  position: absolute;
  background: #005b2a;
  height: 1px;
  width: 100%;
  bottom: -2px;
  left: 0;
}
#globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a .icon {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: solid 1px #005b2a;
}
#globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a .icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  border: 3px solid transparent;
  border-left: 5px solid #005b2a;
}
#globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a:hover::before, #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a:focus::before {
  visibility: hidden;
}
#globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox {
  border-radius: 4px;
}
#globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox a {
  position: relative;
  padding-block: 7px;
  display: block;
  background: #e1e1e1;
  text-decoration: none;
  border-radius: 4px;
}
#globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox a::before {
  position: absolute;
  content: "";
  background: url(../images/common/mushi.png);
  background-repeat: no-repeat;
  background-size: contain;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  left: unset;
}
#globalHeader .ghInner .ghSubNav > .ghSubNavList > li a {
  display: block;
  text-decoration: none;
  color: #005b2a;
}
#globalHeader #ghMenuBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
#globalHeader .ghMenu {
  background: #edf9e5;
}
#globalHeader .ghMenu .ghNav > ul:not([class]) {
  margin: 0 auto;
  font-weight: bold;
}
main {
  display: block;
}

.second #globalHeader {
  background: #fff;
}

#globalFooter {
  color: #fff;
}
#globalFooter .inner .gfLogo a {
  display: block;
  text-decoration: none;
}
#globalFooter .inner .gfInner .gf2 ul li a {
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  letter-spacing: 0.06em;
}
#globalFooter #pageTop {
  display: block;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}
#globalFooter #pageTop:before {
  content: "";
  width: 53px;
  height: 53px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
#globalFooter #pageTop:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bgGreen {
  background: #005b2a !important;
}

.bgGray {
  background: #f3f3f3 !important;
}

.bgBlack {
  background: #2e2e2e !important;
}

.bgSkyblue {
  background: #449ab6 !important;
}

.greenText {
  color: #005b2a !important;
}

.redText {
  color: #d41328 !important;
}

.c-chiken_bg {
  background: #3363ab !important;
}

.c-bengo_bg {
  background: #005b2a !important;
}

.c-pro_bg {
  background: #4c4c4c !important;
}

.c-hojin_bg {
  background: #6d9d2d !important;
}

/* ココから下には記入禁止 */
@media screen and (max-width: 1100px) {
  a,
  button,
  input,
  select,
  textarea,
  summary {
    outline: none;
  }
  a:focus-visible,
  button:focus-visible,
  input:focus-visible,
  select:focus-visible,
  textarea:focus-visible,
  summary:focus-visible {
    outline: 2px solid #005b2a;
  }
  .btn {
    display: inline-block;
    font-weight: 600;
    color: #fff;
    padding: 14px 70px 14px 48px;
    font-size: 24px;
    position: relative;
    transition: 0.3s;
    text-decoration: none;
    background: #2e2e2e;
    text-align: left;
    line-height: 1.6 !important;
    border-radius: 8px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  }
  .btn:hover {
    opacity: 0.7;
  }
  .btn.mid {
    display: inline-block;
    font-size: 18px;
  }
  .btn.semi {
    font-size: 16px;
    padding-left: 18px;
    padding-block: 11px;
    padding-right: 45px;
  }
  .btn.small {
    font-size: 16px;
    padding: 8px 55px 8px 16px;
  }
  .btn.orange {
    background: #f3961e;
  }
  .btn.lightgray {
    background: #4c4c4c;
  }
  .btn.lightgray > span {
    color: #2e2e2e;
  }
  .btn.blue {
    background: #3363ab;
  }
  .btn.blue::after {
    aspect-ratio: 83/150;
  }
  .btn.blue > span {
    color: #2e2e2e;
  }
  .btn.skyblue {
    background: #2b91c1;
  }
  .btn.skyblue::after {
    aspect-ratio: 83/150;
  }
  .btn.skyblue > span {
    color: #2e2e2e;
  }
  .btn.green {
    background: #005b2a;
  }
  .btn.green::after {
    aspect-ratio: 11/15;
  }
  .btn.green > span {
    color: #2e2e2e;
  }
  .btn .c-arrow.black::before {
    background: #fff;
  }
  .btn .c-arrow.black::after {
    background: url(../images/common/arrow.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.orange::before {
    background: #fff;
  }
  .btn .c-arrow.orange::after {
    background: url(../images/common/arrow-orange.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  .btn .c-arrow.lightgray::before {
    background: #fff;
  }
  .btn .c-arrow.lightgray::after {
    background: url(../images/common/arrow-lightgray.svg);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.blue::before {
    background: #fff;
  }
  .btn .c-arrow.blue::after {
    background: url(../images/common/arrow-blue.svg);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.skyblue::before {
    background: #fff;
  }
  .btn .c-arrow.skyblue::after {
    background: url(../images/common/arrow-lightblue.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  .btn .c-arrow.green::before {
    background: #fff;
  }
  .btn .c-arrow.green::after {
    background: url(../images/common/arrow-green.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  #globalHeader .ghInner {
    padding: 30px 20px 66px;
    height: 62px;
  }
  #globalHeader .ghInner .ghLogo {
    max-width: 162px;
  }
  #globalHeader .ghInner .ghLogo a img {
    width: 160px;
  }
  #globalHeader .ghInner .ghLogo a span {
    font-family: "Oswald", sans-serif;
    color: #005b2a;
    display: inline-block;
    font-size: 13px;
    margin-top: 12px;
    padding-left: 5px;
  }
  #globalHeader .ghInner .ghSubNav {
    display: none;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a::before {
    content: "";
    position: absolute;
    background: #005b2a;
    height: 1px;
    width: 100%;
    bottom: -2px;
    left: 0;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a .icon {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: solid 1px #005b2a;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a .icon::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    border: 3px solid transparent;
    border-left: 5px solid #005b2a;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a:hover::before, #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a:focus::before {
    visibility: hidden;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox {
    border-radius: 4px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox a::before {
    position: absolute;
    content: "";
    background: url(../images/common/mushi.png);
    background-repeat: no-repeat;
    background-size: contain;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    left: unset;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList {
    display: none !important;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList > li + li {
    margin-left: 16px;
  }
  #globalHeader #ghMenuBtn {
    width: 45px;
    height: 45px;
    background: #005b2a;
    position: absolute;
    top: 42px;
    right: 10px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    z-index: 40;
  }
  #globalHeader #ghMenuBtn > span {
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    transition: 0.2s transform, 0.2s opacity;
  }
  #globalHeader #ghMenuBtn > span {
    width: 17px;
    height: 2px;
  }
  #globalHeader #ghMenuBtn > span:nth-child(1) {
    transform: translateY(-5px);
  }
  #globalHeader #ghMenuBtn > span:nth-child(3) {
    transform: translateY(5px);
  }
  #globalHeader #ghMenuBtn.isOpen {
    z-index: 20;
    background: #fff;
  }
  #globalHeader #ghMenuBtn.isOpen > span {
    background: #005b2a;
  }
  #globalHeader #ghMenuBtn.isOpen > span:nth-child(1) {
    transform: rotate(45deg);
  }
  #globalHeader #ghMenuBtn.isOpen > span:nth-child(2) {
    opacity: 0;
  }
  #globalHeader #ghMenuBtn.isOpen > span:nth-child(3) {
    transform: rotate(-45deg);
  }
  #globalHeader .ghMenu {
    display: none;
    width: 100%;
    height: calc(100% - 60px);
    position: fixed;
    top: 10px;
    left: 0;
    overflow: hidden;
  }
  #globalHeader .ghMenu .ghNav {
    padding: 30px 20px 40px;
    height: 100%;
    overflow-y: auto;
  }
  #globalHeader .ghMenu .ghNav .hamLogo {
    max-width: 160px;
    width: 100%;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) > li > a {
    display: block;
    padding: 15px 0px;
    color: #222;
    position: relative;
    font-size: 15px;
    letter-spacing: 0.025em;
    background: #fff;
  }
  #globalHeader .ghMenu .ghNav .btnNav_large {
    margin-top: 20px;
  }
  #globalHeader .ghMenu .ghNav .btnNav_large li {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }
  #globalHeader .ghMenu .ghNav .btnNav_large li a {
    padding-block: 18px;
    padding-inline: 22px;
    color: #222;
    display: block;
    position: relative;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    gap: 20px;
    align-items: center;
  }
  #globalHeader .ghMenu .ghNav .btnNav_large li a .img {
    width: 40px;
  }
  #globalHeader .ghMenu .ghNav .btnNav_large li a .img.lawyer img {
    width: 36px;
  }
  #globalHeader .ghMenu .ghNav .btnNav_large li a .img.doctor img {
    width: 38px;
  }
  #globalHeader .ghMenu .ghNav .btnNav_large li a .img.clinic img {
    width: 30px;
  }
  #globalHeader .ghMenu .ghNav .btnNav_large li a .img.professionals img {
    width: 30px;
  }
  #globalHeader .ghMenu .ghNav .btnNav_large li a .img.hojin img {
    width: 30px;
  }
  #globalHeader .ghMenu .ghNav .btnNav_large li a .c-arrow {
    position: absolute;
    right: 20px;
    top: 50%;
    border-radius: 50px;
    transform: translateY(-50%);
  }
  #globalHeader .ghMenu .ghNav .btnNav_large li a .c-arrow::before {
    content: "";
    position: absolute;
    background: #005b2a;
    width: 18px;
    height: 18px;
    top: 50%;
    left: 50%;
    border-radius: 50px;
    transform: translate(-50%, -50%);
  }
  #globalHeader .ghMenu .ghNav .btnNav_large li a .c-arrow::after {
    content: "";
    background: url(../images/common/arrow-white.svg);
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    top: 50%;
    left: calc(50% + 1px);
    transform: translate(-50%, -50%);
    width: 4px;
    height: auto;
    aspect-ratio: 83/150;
  }
  #globalHeader .ghMenu .ghNav .btnNav_large li + li {
    margin-top: 14px;
  }
  #globalHeader .ghMenu .ghNav .sp_navContactBtn {
    margin-top: 30px;
  }
  #globalHeader .ghMenu .ghNav .sp_navContactBtn a {
    display: flex;
    padding: 18px 20px;
    background: #f3961e;
    border-radius: 6px;
    gap: 20px;
    align-items: center;
    font-size: 18px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }
  #globalHeader .ghMenu .ghNav .sp_navContactBtn a .mailIcon {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #globalHeader .ghMenu .ghNav .sp_navContactBtn a .mailIcon img {
    width: 30px;
    aspect-ratio: 44/32;
  }
  #globalHeader .ghMenu .ghNav .sp_navContactBtn a .text {
    color: #fff;
    font-weight: 600;
  }
  #globalFooter #pageTop:before {
    content: "";
    width: 53px;
    height: 53px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  /* ココから下には記入禁止 */
}
@media screen and (min-width: 1101px) {
  a,
  button,
  input,
  select,
  textarea,
  summary {
    outline: none;
  }
  a:focus-visible,
  button:focus-visible,
  input:focus-visible,
  select:focus-visible,
  textarea:focus-visible,
  summary:focus-visible {
    outline: 2px solid #005b2a;
  }
  .btn {
    display: inline-block;
    font-weight: 600;
    color: #fff;
    padding: 14px 70px 14px 48px;
    font-size: 24px;
    position: relative;
    transition: 0.3s;
    text-decoration: none;
    background: #2e2e2e;
    text-align: left;
    line-height: 1.6 !important;
    border-radius: 8px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  }
  .btn:hover {
    opacity: 0.7;
  }
  .btn.mid {
    display: inline-block;
    font-size: 18px;
  }
  .btn.semi {
    font-size: 16px;
    padding-left: 18px;
    padding-block: 11px;
    padding-right: 45px;
  }
  .btn.small {
    font-size: 16px;
    padding: 8px 55px 8px 16px;
  }
  .btn.orange {
    background: #f3961e;
  }
  .btn.lightgray {
    background: #4c4c4c;
  }
  .btn.lightgray > span {
    color: #2e2e2e;
  }
  .btn.blue {
    background: #3363ab;
  }
  .btn.blue::after {
    aspect-ratio: 83/150;
  }
  .btn.blue > span {
    color: #2e2e2e;
  }
  .btn.skyblue {
    background: #2b91c1;
  }
  .btn.skyblue::after {
    aspect-ratio: 83/150;
  }
  .btn.skyblue > span {
    color: #2e2e2e;
  }
  .btn.green {
    background: #005b2a;
  }
  .btn.green::after {
    aspect-ratio: 11/15;
  }
  .btn.green > span {
    color: #2e2e2e;
  }
  .btn .c-arrow.black::before {
    background: #fff;
  }
  .btn .c-arrow.black::after {
    background: url(../images/common/arrow.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.orange::before {
    background: #fff;
  }
  .btn .c-arrow.orange::after {
    background: url(../images/common/arrow-orange.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  .btn .c-arrow.lightgray::before {
    background: #fff;
  }
  .btn .c-arrow.lightgray::after {
    background: url(../images/common/arrow-lightgray.svg);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.blue::before {
    background: #fff;
  }
  .btn .c-arrow.blue::after {
    background: url(../images/common/arrow-blue.svg);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.skyblue::before {
    background: #fff;
  }
  .btn .c-arrow.skyblue::after {
    background: url(../images/common/arrow-lightblue.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  .btn .c-arrow.green::before {
    background: #fff;
  }
  .btn .c-arrow.green::after {
    background: url(../images/common/arrow-green.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  #globalHeader .ghInner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-inline: auto;
    padding-block: 14px;
    width: 90%;
  }
  #globalHeader .ghInner .ghLogo {
    max-width: 216px;
    transition: 0.25s;
    position: relative;
    top: 0;
  }
  #globalHeader .ghInner .ghLogo a img {
    width: 246px;
  }
  #globalHeader .ghInner .ghLogo a span {
    font-family: "Oswald", sans-serif;
    color: #005b2a;
    display: inline-block;
    font-size: 18px;
    padding-left: 10px;
    margin-top: 25px;
  }
  #globalHeader .ghInner .ghSubNav {
    font-size: 16px;
    font-weight: bold;
    margin-right: 0 !important;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a::before {
    content: "";
    position: absolute;
    background: #005b2a;
    height: 1px;
    width: 100%;
    bottom: -2px;
    left: 0;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a .icon {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: solid 1px #005b2a;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a .icon::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    border: 3px solid transparent;
    border-left: 5px solid #005b2a;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a:hover::before, #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a:focus::before {
    visibility: hidden;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox {
    border-radius: 4px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox a::before {
    position: absolute;
    content: "";
    background: url(../images/common/mushi.png);
    background-repeat: no-repeat;
    background-size: contain;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    left: unset;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList {
    display: flex;
    position: relative;
    transition: top 0.25s;
    top: 0;
    margin-top: 14px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList > li + li {
    margin-left: 31px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList > li a {
    transition: 0.2s opacity;
    font-size: 16px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList > li a:hover {
    text-decoration: underline;
    text-decoration-color: #005b2a;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList > li a:hover .on {
    color: #005b2a;
    opacity: 1;
  }
  #globalHeader #ghMenuBtn {
    display: none !important;
  }
  #globalHeader .ghMenu {
    display: block !important;
  }
  #globalHeader .ghMenu .ghNav {
    display: none;
  }
  #globalHeader .ghMenu .ghNav .ghSubNavList {
    display: none !important;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) {
    display: flex;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) > li {
    flex: 1;
    position: relative;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) > li + li:before, #globalHeader .ghMenu .ghNav > ul:not([class]) > li:nth-child(1):before, #globalHeader .ghMenu .ghNav > ul:not([class]) > li:last-child:after {
    content: "";
    display: block;
    width: 2px;
    height: calc(100% - 20px);
    background: #fff;
    position: absolute;
    top: 10px;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) > li + li:before, #globalHeader .ghMenu .ghNav > ul:not([class]) > li:nth-child(1):before {
    left: 0;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) > li:last-child:after {
    right: 0;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) > li > a {
    display: block;
    padding: 18px 10px;
    text-align: center;
    transition: 0.2s background;
  }
  #globalHeader .ghMenu .ghNav > ul:not([class]) > li > a:hover {
    background: #003500;
  }
  #globalFooter #pageTop:before {
    content: "";
    width: 53px;
    height: 53px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  /* ココから下には記入禁止 */
}
@media screen and (max-width: 768px) {
  a,
  button,
  input,
  select,
  textarea,
  summary {
    outline: none;
  }
  a:focus-visible,
  button:focus-visible,
  input:focus-visible,
  select:focus-visible,
  textarea:focus-visible,
  summary:focus-visible {
    outline: 2px solid #005b2a;
  }
  body {
    font-size: 14px;
    line-height: 2;
  }
  ul,
  ol {
    font-size: 14px;
  }
  .tel {
    color: #005b2a;
    text-decoration: underline;
  }
  .btn {
    display: inline-block;
    font-weight: 600;
    color: #fff;
    padding: 14px 70px 14px 48px;
    font-size: 24px;
    position: relative;
    transition: 0.3s;
    text-decoration: none;
    background: #2e2e2e;
    text-align: left;
    line-height: 1.6 !important;
    border-radius: 8px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    width: 100%;
    font-size: 20px;
  }
  .btn:hover {
    opacity: 0.7;
  }
  .btn.mid {
    display: inline-block;
    font-size: 18px;
    font-size: 15px;
    padding: 10px 13px;
  }
  .btn.semi {
    font-size: 16px;
    padding-left: 18px;
    padding-block: 11px;
    padding-right: 45px;
    font-size: 14px;
  }
  .btn.semi .c-arrow {
    display: block;
    width: 20px;
    height: 20px;
  }
  .btn.semi .c-arrow::before {
    width: 18px;
    height: 18px;
  }
  .btn.semi .c-arrow::after {
    width: 4px;
    height: auto;
  }
  .btn.small {
    font-size: 16px;
    padding: 8px 55px 8px 16px;
  }
  .btn.orange {
    background: #f3961e;
  }
  .btn.lightgray {
    background: #4c4c4c;
  }
  .btn.lightgray > span {
    color: #2e2e2e;
  }
  .btn.blue {
    background: #3363ab;
  }
  .btn.blue::after {
    aspect-ratio: 83/150;
  }
  .btn.blue > span {
    color: #2e2e2e;
  }
  .btn.skyblue {
    background: #2b91c1;
  }
  .btn.skyblue::after {
    aspect-ratio: 83/150;
  }
  .btn.skyblue > span {
    color: #2e2e2e;
  }
  .btn.green {
    background: #005b2a;
  }
  .btn.green::after {
    aspect-ratio: 11/15;
  }
  .btn.green > span {
    color: #2e2e2e;
  }
  .btn .c-arrow {
    display: block;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .btn .c-arrow::before {
    content: "";
    width: 18px;
    height: 18px;
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  .btn .c-arrow::after {
    content: "";
    width: 4px;
    height: auto;
    position: absolute;
    background: url(../images/common/arrow-white.svg);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 83/150;
    background-size: contain;
  }
  .btn .c-arrow.black::before {
    background: #fff;
  }
  .btn .c-arrow.black::after {
    background: url(../images/common/arrow.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.orange::before {
    background: #fff;
  }
  .btn .c-arrow.orange::after {
    background: url(../images/common/arrow-orange.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  .btn .c-arrow.lightgray::before {
    background: #fff;
  }
  .btn .c-arrow.lightgray::after {
    background: url(../images/common/arrow-lightgray.svg);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.blue::before {
    background: #fff;
  }
  .btn .c-arrow.blue::after {
    background: url(../images/common/arrow-blue.svg);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.skyblue::before {
    background: #fff;
  }
  .btn .c-arrow.skyblue::after {
    background: url(../images/common/arrow-lightblue.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  .btn .c-arrow.green::before {
    background: #fff;
  }
  .btn .c-arrow.green::after {
    background: url(../images/common/arrow-green.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  #globalHeader .lineLabel .labelInner {
    padding-block: 5px;
    width: 95%;
  }
  #globalHeader .lineLabel .labelInner p {
    font-size: 9px;
    line-height: 1.4;
  }
  #globalHeader .ghInner .ghLogo a span {
    font-family: "Oswald", sans-serif;
    color: #005b2a;
    display: inline-block;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a::before {
    content: "";
    position: absolute;
    background: #005b2a;
    height: 1px;
    width: 100%;
    bottom: -2px;
    left: 0;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a .icon {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: solid 1px #005b2a;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a .icon::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    border: 3px solid transparent;
    border-left: 5px solid #005b2a;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a:hover::before, #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a:focus::before {
    visibility: hidden;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox {
    border-radius: 4px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox a::before {
    position: absolute;
    content: "";
    background: url(../images/common/mushi.png);
    background-repeat: no-repeat;
    background-size: contain;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    left: unset;
  }
  #globalFooter {
    background-image: url(../images/common/footer_bg_sp.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 35px;
    padding-bottom: 40px;
  }
  #globalFooter .inner {
    padding-inline: 20px;
  }
  #globalFooter .inner .gfLogo a {
    max-width: 160px;
  }
  #globalFooter .inner .gfInner .gf1 {
    margin-top: 20px;
  }
  #globalFooter .inner .gfInner .gf1 .gfLogo + p {
    margin-top: 30px;
    font-size: 13px;
    line-height: 1.53;
    letter-spacing: 0.12em;
  }
  #globalFooter .inner .gfInner .gf1 p .telfax {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.7;
    margin-top: 16px;
    display: block;
  }
  #globalFooter .inner .gfInner .gf2 {
    margin-top: 34px;
  }
  #globalFooter .inner .gfInner .gf2 ul li a {
    font-size: 12px;
  }
  #globalFooter .inner .gfCopyright {
    font-size: 9px;
    margin-top: 40px;
  }
  #globalFooter #pageTop {
    right: 16px;
    bottom: 32px;
    width: 32px;
    height: 32px;
  }
  #globalFooter #pageTop:before {
    content: "";
    width: 53px;
    height: 53px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  #globalFooter #pageTop:after {
    background: url(../images/common/pagetop-arrow_sp.png);
    width: 10px;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 20/12;
  }
  /* ココから下には記入禁止 */
  .forPC {
    display: none !important;
  }
}
@media print, screen and (min-width: 769px) {
  a,
  button,
  input,
  select,
  textarea,
  summary {
    outline: none;
  }
  a:focus-visible,
  button:focus-visible,
  input:focus-visible,
  select:focus-visible,
  textarea:focus-visible,
  summary:focus-visible {
    outline: 2px solid #005b2a;
  }
  body {
    font-size: 16px;
    line-height: 1.75;
  }
  .tel {
    color: inherit !important;
    text-decoration: none !important;
    pointer-events: none;
  }
  .btn {
    display: inline-block;
    font-weight: 600;
    color: #fff;
    padding: 14px 70px 14px 48px;
    font-size: 24px;
    position: relative;
    transition: 0.3s;
    text-decoration: none;
    background: #2e2e2e;
    text-align: left;
    line-height: 1.6 !important;
    border-radius: 8px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    font-size: 24px;
  }
  .btn:hover {
    opacity: 0.7;
  }
  .btn.mid {
    display: inline-block;
    font-size: 18px;
    padding-left: 34px;
    padding-block: 11px;
    padding-right: 70px;
  }
  .btn.mid > .c-arrow {
    width: 27px;
    height: 27px;
  }
  .btn.mid > .c-arrow::before {
    width: 27px;
    height: 27px;
  }
  .btn.mid > .c-arrow::after {
    width: 6px;
    height: auto;
    left: calc(50% + 0px);
  }
  .btn.semi {
    font-size: 16px;
    padding-left: 18px;
    padding-block: 11px;
    padding-right: 45px;
    width: 240px;
  }
  .btn.semi .c-arrow {
    width: 21px;
    height: 21px;
    right: 16px;
  }
  .btn.semi .c-arrow::before {
    width: 21px;
    height: 21px;
  }
  .btn.semi .c-arrow::after {
    width: 6px;
    height: auto;
    left: calc(50% - 0px);
  }
  .btn.small {
    font-size: 16px;
    padding: 8px 55px 8px 16px;
    width: 160px;
  }
  .btn.small > .c-arrow {
    width: 21px;
    height: 21px;
  }
  .btn.small > .c-arrow::before {
    width: 21px;
    height: 21px;
  }
  .btn.small > .c-arrow::after {
    width: 6px;
    height: auto;
    left: calc(50% + 1px);
  }
  .btn.orange {
    background: #f3961e;
  }
  .btn.lightgray {
    background: #4c4c4c;
  }
  .btn.lightgray > span {
    color: #2e2e2e;
  }
  .btn.blue {
    background: #3363ab;
  }
  .btn.blue::after {
    aspect-ratio: 83/150;
  }
  .btn.blue > span {
    color: #2e2e2e;
  }
  .btn.skyblue {
    background: #2b91c1;
  }
  .btn.skyblue::after {
    aspect-ratio: 83/150;
  }
  .btn.skyblue > span {
    color: #2e2e2e;
  }
  .btn.green {
    background: #005b2a;
  }
  .btn.green::after {
    aspect-ratio: 11/15;
  }
  .btn.green > span {
    color: #2e2e2e;
  }
  .btn .c-arrow {
    width: 36px;
    height: 36px;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }
  .btn .c-arrow::before {
    content: "";
    width: 36px;
    height: 36px;
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0px;
    bottom: 0;
    margin: auto;
  }
  .btn .c-arrow::after {
    content: "";
    position: absolute;
    width: 9px;
    height: auto;
    background: url(../images/common/arrow-white.svg);
    top: 50%;
    left: calc(50% + 0px);
    transform: translate(-50%, -50%);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.black::before {
    background: #fff;
  }
  .btn .c-arrow.black::after {
    background: url(../images/common/arrow.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.orange::before {
    background: #fff;
  }
  .btn .c-arrow.orange::after {
    background: url(../images/common/arrow-orange.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  .btn .c-arrow.lightgray::before {
    background: #fff;
  }
  .btn .c-arrow.lightgray::after {
    background: url(../images/common/arrow-lightgray.svg);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.blue::before {
    background: #fff;
  }
  .btn .c-arrow.blue::after {
    background: url(../images/common/arrow-blue.svg);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.skyblue::before {
    background: #fff;
  }
  .btn .c-arrow.skyblue::after {
    background: url(../images/common/arrow-lightblue.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  .btn .c-arrow.green::before {
    background: #fff;
  }
  .btn .c-arrow.green::after {
    background: url(../images/common/arrow-green.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  #globalHeader .lineLabel .labelInner {
    max-width: 1570px;
    padding-block: 5px;
    width: 90%;
  }
  #globalHeader .lineLabel .labelInner p {
    font-size: 13px;
  }
  #globalHeader .lineLabel .labelInner .labelLink::before {
    width: 16px;
    height: 16px;
    background-size: contain;
    left: -28px;
    top: 5px;
  }
  #globalHeader .ghInner .ghLogo a span {
    font-family: "Oswald", sans-serif;
    color: #005b2a;
    display: inline-block;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop {
    gap: 18px;
    align-items: center;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a {
    font-size: 14px;
    text-underline-offset: 5px;
    gap: 8px;
    align-items: center;
    transition: all 0.3s;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a::before {
    content: "";
    position: absolute;
    background: #005b2a;
    height: 1px;
    width: 100%;
    bottom: -2px;
    left: 0;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a .icon {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: solid 1px #005b2a;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a .icon::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    border: 3px solid transparent;
    border-left: 5px solid #005b2a;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a:hover::before, #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a:focus::before {
    visibility: hidden;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox {
    border-radius: 4px;
    width: 224px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox a {
    min-height: 40px;
    width: 100%;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox a::before {
    position: absolute;
    content: "";
    background: url(../images/common/mushi.png);
    background-repeat: no-repeat;
    background-size: contain;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    left: unset;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavList > li + li {
    margin-left: 31px;
  }
  .section .inner {
    max-width: 1102px;
    width: 90%;
    margin: 0 auto;
  }
  .second {
    padding-top: 120px;
  }
  #globalFooter {
    background-image: url(../images/common/footer_bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  #globalFooter .inner {
    max-width: 1102px;
    width: 90%;
    margin: 0 auto;
  }
  #globalFooter .inner .gfLogo a {
    max-width: 216px;
  }
  #globalFooter .inner .gfInner {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  #globalFooter .inner .gfInner .gf1 .gfLogo + p {
    margin-top: 36px;
  }
  #globalFooter .inner .gfInner .gf2 ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 50px;
    row-gap: 16px;
    max-width: 470px;
  }
  #globalFooter .inner .gfInner .gf2 ul li {
    width: calc((100% - 50px) / 2);
  }
  #globalFooter .inner .gfInner .gf2 ul li a {
    font-size: 14px;
  }
  #globalFooter .inner .gfCopyright {
    font-size: 13px;
    margin-top: 130px;
  }
  #globalFooter #pageTop {
    width: 53px;
    height: 53px;
    right: 38px;
    bottom: 53px;
    transition: 0.2s transform;
  }
  #globalFooter #pageTop:hover {
    transform: translateY(-10px);
    background-color: rgba(255, 255, 255, 0.7);
  }
  #globalFooter #pageTop:before {
    content: "";
    width: 53px;
    height: 53px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  #globalFooter #pageTop:after {
    background: url(../images/common/pagetop-arrow.png);
    width: 16px;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 32/21;
  }
  /* ココから下には記入禁止 */
  .forSP {
    display: none !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1100px) {
  a,
  button,
  input,
  select,
  textarea,
  summary {
    outline: none;
  }
  a:focus-visible,
  button:focus-visible,
  input:focus-visible,
  select:focus-visible,
  textarea:focus-visible,
  summary:focus-visible {
    outline: 2px solid #005b2a;
  }
  .btn {
    display: inline-block;
    font-weight: 600;
    color: #fff;
    padding: 14px 70px 14px 48px;
    font-size: 24px;
    position: relative;
    transition: 0.3s;
    text-decoration: none;
    background: #2e2e2e;
    text-align: left;
    line-height: 1.6 !important;
    border-radius: 8px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  }
  .btn:hover {
    opacity: 0.7;
  }
  .btn.mid {
    display: inline-block;
    font-size: 18px;
  }
  .btn.semi {
    font-size: 16px;
    padding-left: 18px;
    padding-block: 11px;
    padding-right: 45px;
  }
  .btn.small {
    font-size: 16px;
    padding: 8px 55px 8px 16px;
  }
  .btn.orange {
    background: #f3961e;
  }
  .btn.lightgray {
    background: #4c4c4c;
  }
  .btn.lightgray > span {
    color: #2e2e2e;
  }
  .btn.blue {
    background: #3363ab;
  }
  .btn.blue::after {
    aspect-ratio: 83/150;
  }
  .btn.blue > span {
    color: #2e2e2e;
  }
  .btn.skyblue {
    background: #2b91c1;
  }
  .btn.skyblue::after {
    aspect-ratio: 83/150;
  }
  .btn.skyblue > span {
    color: #2e2e2e;
  }
  .btn.green {
    background: #005b2a;
  }
  .btn.green::after {
    aspect-ratio: 11/15;
  }
  .btn.green > span {
    color: #2e2e2e;
  }
  .btn .c-arrow.black::before {
    background: #fff;
  }
  .btn .c-arrow.black::after {
    background: url(../images/common/arrow.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.orange::before {
    background: #fff;
  }
  .btn .c-arrow.orange::after {
    background: url(../images/common/arrow-orange.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  .btn .c-arrow.lightgray::before {
    background: #fff;
  }
  .btn .c-arrow.lightgray::after {
    background: url(../images/common/arrow-lightgray.svg);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.blue::before {
    background: #fff;
  }
  .btn .c-arrow.blue::after {
    background: url(../images/common/arrow-blue.svg);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .btn .c-arrow.skyblue::before {
    background: #fff;
  }
  .btn .c-arrow.skyblue::after {
    background: url(../images/common/arrow-lightblue.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  .btn .c-arrow.green::before {
    background: #fff;
  }
  .btn .c-arrow.green::after {
    background: url(../images/common/arrow-green.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  #globalHeader .ghInner .ghLogo a span {
    font-family: "Oswald", sans-serif;
    color: #005b2a;
    display: inline-block;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a::before {
    content: "";
    position: absolute;
    background: #005b2a;
    height: 1px;
    width: 100%;
    bottom: -2px;
    left: 0;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a .icon {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: solid 1px #005b2a;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a .icon::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    border: 3px solid transparent;
    border-left: 5px solid #005b2a;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a:hover::before, #globalHeader .ghInner .ghSubNav > .ghSubNavTop li:not(:last-child) a:focus::before {
    visibility: hidden;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox {
    border-radius: 4px;
  }
  #globalHeader .ghInner .ghSubNav > .ghSubNavTop li.searchBox a::before {
    position: absolute;
    content: "";
    background: url(../images/common/mushi.png);
    background-repeat: no-repeat;
    background-size: contain;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    left: unset;
  }
  #globalHeader #ghMenuBtn {
    width: 60px;
    height: 60px;
  }
  #globalFooter #pageTop:before {
    content: "";
    width: 53px;
    height: 53px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  /* ココから下には記入禁止 */
}
@media screen and (min-width: 769px) and (max-width: 1160px) {
  #globalHeader .ghInner .ghSubNav .ghContact {
    width: 300px;
  }
  #globalHeader .ghInner .ghSubNav .ghContact a:nth-child(1) {
    flex-basis: 40%;
    font-size: 14px;
  }
  #globalHeader .ghInner .ghSubNav .ghContact a:nth-child(2) {
    flex-basis: 60%;
    font-size: 14px;
  }
  #globalHeader .ghInner .ghSubNav {
    margin-right: 200px;
    font-size: 15px;
  }
}